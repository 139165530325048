import { make } from "../lib/dom"

document.addEventListener("DOMContentLoaded", () => {
  const elements = document.querySelectorAll(".cursor-comming-soon")
  if (elements.length == 0) return

  const cursorElement = make("div", "break-words z-30 bg-black text-white fixed w-28 h-28 rounded-full flex items-center justify-center text-center px-5 pt-8 custom-mouse-circle".split(" "))
  cursorElement.textContent = "Still in Private"
  cursorElement.style.cursor = "none"
  cursorElement.style.display = "none"

  document.body.appendChild(cursorElement)

  const circleRadius = 56;

  elements.forEach((element => {
    element.addEventListener("mousemove", (event: MouseEvent) => {
      cursorElement.style.left = `${event.clientX - circleRadius}px`
      cursorElement.style.top = `${event.clientY - circleRadius}px`
    })

    element.addEventListener("mousedown", () => {
      cursorElement.style.transform = "scale(1.1)";
    })

    element.addEventListener("mouseup", () => {
      cursorElement.style.transform = "scale(1)";
    })

    element.addEventListener("mouseenter", () => {
      cursorElement.style.display = "block";
    })

    element.addEventListener("mouseleave", () => {
      cursorElement.style.display = 'none'
    })
  }))
})
