import "../css/frontend.scss";
import { Application } from "@hotwired/stimulus"
import { registerControllers } from "stimulus-vite-helpers"

const controllers = import.meta.glob('./../src/controllers/*_controller.ts', { eager: true })
import "../src/scripts/custom_cursor"
import.meta.glob("../images/icons/**", { eager: true });

const application = Application.start()
registerControllers(application, controllers)

console.log(controllers)
